/* asap-400normal - latin */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Asap Regular '),
    local('Asap-Regular'),
    url('./files/asap-latin-400.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/asap-latin-400.woff') format('woff'); /* Modern Browsers */
}

/* asap-400italic - latin */
@font-face {
  font-family: 'Asap';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Asap Regular italic'),
    local('Asap-Regularitalic'),
    url('./files/asap-latin-400italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/asap-latin-400italic.woff') format('woff'); /* Modern Browsers */
}

/* asap-500normal - latin */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
    local('Asap Medium '),
    local('Asap-Medium'),
    url('./files/asap-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/asap-latin-500.woff') format('woff'); /* Modern Browsers */
}

/* asap-500italic - latin */
@font-face {
  font-family: 'Asap';
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src:
    local('Asap Medium italic'),
    local('Asap-Mediumitalic'),
    url('./files/asap-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/asap-latin-500italic.woff') format('woff'); /* Modern Browsers */
}

/* asap-600normal - latin */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src:
    local('Asap SemiBold '),
    local('Asap-SemiBold'),
    url('./files/asap-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/asap-latin-600.woff') format('woff'); /* Modern Browsers */
}

/* asap-600italic - latin */
@font-face {
  font-family: 'Asap';
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src:
    local('Asap SemiBold italic'),
    local('Asap-SemiBolditalic'),
    url('./files/asap-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/asap-latin-600italic.woff') format('woff'); /* Modern Browsers */
}

/* asap-700normal - latin */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Asap Bold '),
    local('Asap-Bold'),
    url('./files/asap-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/asap-latin-700.woff') format('woff'); /* Modern Browsers */
}

/* asap-700italic - latin */
@font-face {
  font-family: 'Asap';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Asap Bold italic'),
    local('Asap-Bolditalic'),
    url('./files/asap-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/asap-latin-700italic.woff') format('woff'); /* Modern Browsers */
}

